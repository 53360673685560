import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, Component, OnInit, ViewChild, Inject, SecurityContext } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { interval, Observable, Subject } from 'rxjs';
import { map, shareReplay, take, takeUntil } from 'rxjs/operators';
import { PdfService } from 'src/app/core/services/app/pdf/pdf.service';

import { AuthService } from './core/services/app/auth/auth.service';
import { handleError, MSG_ERROR } from './core/services/utils/error-handling';
import { ErrorService } from './core/services/app/error/error.service';
import { NotificationService } from './core/services/app/notification/notification.service';
import { STORAGE_ISMOBILE } from './core/constants/storage-keys';
import { UseExistingWebDriver } from 'protractor/built/driverProviders';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { isMobile } from './core/utils/files';
import { LanguageService } from './core/services/entities/language.service';
import { getDefaultNavigationExtras } from './core/utils/router';
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterViewInit {
  isMobile$: Observable<boolean>;
  title = 'Audify';
  userConfig$ = this.authService.userConfig$;

  logout = false;
  private redirect: boolean = false;

  @ViewChild(PdfJsViewerComponent, { static: true }) externalPdfViewer!: PdfJsViewerComponent;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private breakpointObserver: BreakpointObserver,
    private error: ErrorService,
    private notification: NotificationService,
    translate: TranslateService,
    private authService: AuthService,
    private pdfService: PdfService,
    private readonly dompurifySanitizer: NgDompurifySanitizer
  ) {
    this.authService.getConfig();
    translate.addLangs(['es_ES', 'en_US', 'pt_PRT']);
    translate.setDefaultLang('en_US');
    localStorage.removeItem("yearTemp");
    this.userConfig$.subscribe(res => {
      if (res) {
        let value = '';
        let idm = '';
        if (res.idioma === 'ING') {
          value = 'en_US';
          idm = 'ENG';
          translate.use(value);
        } else if (res.idioma === 'ESP') {
          value = 'es_ES';
          idm = 'ESP';
          translate.use(value);
        } else if (res.idioma === 'POR') {
          value = 'pt_PRT';
          idm = 'POR';
          translate.use(value);
        } else {
          value = 'en_US';
          idm = 'ENG';
          translate.use(value);
        }
        //const callback = () =>
          //this.router.navigate(['.'], getDefaultNavigationExtras({ queryParams: { lang: idm } }));
        //this.language.getUserGeographies(idm).subscribe(callback, callback);
        (window as any).webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({ action: 'setLang', data: idm }));
      }
    });

    this.isMobile$ = this.breakpointObserver.observe('(max-width: 575.9998px)').pipe(
      map(result => result.matches),
      shareReplay(),
    );
  }

  ngOnInit() {    
    this.error.errors$.subscribe(err => handleError(err, this.notification));
    this.isMobile$.pipe(take(1)).subscribe(() => {
      history.pushState(null, '', location.href);
    });
    //charge redirect and loginUrl
      this.checkIsMobile();
      let refreshToken = setInterval(() => {
        if (!this.authService.semaforo) {
          this.authService.getAccessToken().subscribe(result => {
            if (typeof result !== 'undefined' && result) {
              const fechaExpiracion = +(atob(result).split("#")[2]);
              const ahora = new Date().getTime();
              if (ahora > fechaExpiracion) {
                console.log("expiración token check incorrecto");
                this.redirectToEstructural();
              } else {
                console.log("expiración token check correcto");
              }
            } else {
              // redirect o error
              console.log("token vacio");
              this.redirectToEstructural();
            }
          }, error => {
            console.log("expiración getAccessToken");
              this.redirectToEstructural();
          });
        } else {
         clearInterval(refreshToken);
}
      }, 90000); // 180 segundos

  }

  ngAfterViewInit() {
    const subject = new Subject();
    interval(500)
      .pipe(takeUntil(subject))
      .subscribe(() => {
        this.pdfService.setPdfEditor(this.externalPdfViewer);
        subject.next();
        subject.complete();
      });
  }

  checkIsMobile() {
    const isMobile = (window as any)?.os as string | undefined;
    if (isMobile && typeof isMobile !== 'undefined') {
      localStorage.setItem(STORAGE_ISMOBILE, isMobile);
    } else {
      //check token
      if (!this.authService.semaforo) {
        this.authService.getAccessToken().subscribe(result => {
          if (!result || result === undefined) {
            // si no existe, redirección
            this.redirectToEstructural();
          }
        }, error => {
          //console.error(error);
          this.redirectToEstructural();
        });
      }
    }
  }

  redirectToEstructural() {
    if (isMobile()) {
      this.authService.logout();
    } else {
      return new Promise((resolve, reject) => {
        // Llamada al fichero de configuración
        this.http.get('/config.json').subscribe(
          (response: any) => {
            if(response.redirect){
              this.authService.logout();
              this.document.location.href = this.dompurifySanitizer.sanitize(SecurityContext.HTML, response.loginUrl);
              resolve(response);
            }
            else{
              this.authService.semaforo = true;
            }
          },
          (error: any) => {
            console.error(error);
            reject();
          },
        );
      });

    }
  }
}
